@media only screen and (min-width: 600px) {
    .post-body {
        margin-left: 100px;
        margin-right: 100px;
    }

    .similar-words-associogram {
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
        max-height: 600px;
    }

}

@media only screen and (max-width: 600px) {
    .post-body {
        margin-left: 5%;
        margin-right: 5%;
    }

    .similar-words-associogram {
        width: 100%;
        max-height: 600px;
    }

}

.post-title {
    margin-top: 30px;
    margin-bottom: 50px;
}

.post-abstract {
    margin-top: 30px;
    margin-bottom: 50px;
}

.post-paragraph {
    margin-top: 30px;
    margin-bottom: 30px;
}
.post-paragraph {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: justify;
}

.post-paragraph-centered {
    text-align: center;
}

.post-title {
    margin-top: 30px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 600px) {
    .post-image {
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
    }
}

@media only screen and (min-width: 600px) {
    .post-image {
        width: 100%;
    }
}
.card {
    flex: 1;
    min-width: 25ch;
}

.category-img {
    /* height: 350px; */
    object-fit: cover;
}

.card-text {
    text-align: justify;
    font-size: 14px;
}


.nav-link {
    padding-right: 10rem;
}

.card-title {
    font-weight: 800;
}

.card {
    padding-bottom: 30px;
}
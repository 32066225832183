.quote {
    background: rgba(255, 255, 255, 0.9);
}

.quote-text {
    font-size: 18px;
    text-align: center;
}

.card.quote {
    padding-bottom: 0;
}

.quote-image {
    margin: 1rem;
}

@media only screen and (max-width: 600px) {
    .quote-image {
        margin: 0;
    }

    .custom-container {
        padding: 0;
    }
}
.logo-subscript {
    font-weight: bolder;
    font-size: 15px;
}

.logo {
    font-family: caveat-bold;
}

.logo-subscript {
    font-family: caveat-bold;
}


@media only screen and (min-width: 600px) {
    .logo {
        font-size: 30px;
        display: block;
    }

    .logo-subscript {
        padding-left: 20px;
        font-size: 20px;
    }

    .logo-container {
        line-height: 22px;
    }

}

@media only screen and (max-width: 600px) {
    .logo {
        font-size: 15px;
        display: block;
    }

    .logo-subscript {
        padding-left: 20px;
        font-size: 10px;
    }

    .flag-group-navigation {
        display: none;
    }

    .logo-container {
        line-height: 10px;
    }

}

@font-face {
    font-family: 'caveat-bold';
    font-style: normal;
    font-weight: normal;
    src: url('Merienda-Bold.ttf');
}
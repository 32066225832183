@media only screen and (min-width: 600px) {
    .post-body {
        margin-left: 100px;
        margin-right: 100px;
    }

}

@media only screen and (max-width: 600px) {
    .post-body {
        margin-left: 5%;
        margin-right: 5%;
    }

}

.post-title {
    margin-top: 30px;
    margin-bottom: 50px;
}

.post-abstract {
    margin-top: 30px;
    margin-bottom: 50px;
}

.post-paragraph {
    margin-top: 30px;
    margin-bottom: 30px;
}

.associogram {
    width: 50%;
    max-height: 400px;
}

.centered-quote {
    font-weight: bold;
    text-align: center;
    font-style: italic;
}

.post-headline-image {
    width: 100%;
}
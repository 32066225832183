.post-paragraph {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: justify;
}

.post-title {
    margin-top: 30px;
    margin-bottom: 50px;
}

.post-paragraph-correction {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: justify;
    color: red;
}

@media only screen and (max-width: 1000px) {
    .post-image-noun {
        width: 100%;
    }
}

@media only screen and (min-width: 1000px) {
    .post-image-noun {
        width: 33%;
        max-height: 400px;
    }
}
.post-paragraph {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: justify;
}

.post-title {
    margin-top: 30px;
    margin-bottom: 50px;
}

.post-paragraph-correction {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: justify;
    color: red;
}

@media only screen and (max-width: 600px) {
    .post-image {
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
    }
}

@media only screen and (min-width: 600px) {
    .post-image {
        width: 100%;
    }
}

.highlighter-text-red {
    background-color: #f4cccc;
    /* font-weight: bold; */
    font-style: italic;
}

.highlighter-text-purple {
    background-color: #d5cee4;
    /* font-weight: bold; */
    font-style: italic;
}

.highlighter-text-red-bolded {
    background-color: #f4cccc;
    font-weight: bold;
    font-style: italic;
}

.highlighter-text-purple-bolded {
    background-color: #d5cee4;
    font-weight: bold;
    font-style: italic;
}
@media only screen and (min-width: 600px) {


    .portrait {
        width: 100%;
        padding-left: 20%;
        padding-right: 20%;
        padding-bottom: 10%;
    }
}

@media only screen and (max-width: 600px) {


    .about-me-section {
        padding: 5%;
    }

    .portrait {
        max-width: 100%;
        padding: 5%;
    }

    .experience-item {
        padding-bottom: 15px;
    }
}

.left-section {
    text-align: justify;
}
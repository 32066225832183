.flag-group {
    width: 110px;
    height: 20px;
    float: left;
    /* background-color: aqua; */
}

.flag {
    width: 30px;
    height: 20px;
    margin-right: 10px;
    float: left;
    cursor: pointer;
}

.flag:last-child {
    margin-right: 0px;
}
@media only screen and (min-width: 600px) {
    .categoryContainer {
        padding-top: 40px;
        padding-bottom: 30px;
        margin-right: 10%;
        margin-left: 10%;
    }

}

@media only screen and (max-width: 600px) {
    .categoryContainer {
        padding-top: 40px;
        padding-bottom: 30px;
        margin-right: 5%;
        margin-left: 5%;
    }

}

.post-preview {
    margin-bottom: 20px;
}